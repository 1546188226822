import React, {useState} from 'react'
import { logo } from '../assets'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

function Header() {

  const [nav, setNav] = useState(false);
  const handleNav = () => {
    setNav(!nav);
  };
  
    return (
<div className='fixed w-full h-20 shadow-xl z-[100] bg-white'>
      <div className='flex justify-between items-center w-full px-2 2xl:px-16 pt-4'>
          <a href="https://sivioinstitute.org/" target='_blank' rel='noreferrer'>
            <img src={logo} width='125' height='50' alt='headerImage' />
          </a>
        <div>
          <ul className='hidden md:flex'>
            <a href='#candidates'>
              <li className='ml-10 text-sm uppercase hover:border-b'>Presidential Candidates</li>
            </a>

            <a href='#summary'>
              <li className='ml-10 text-sm uppercase hover:border-b'>
                Summary of Activities
              </li>
            </a>

            <a href='#details'>
              <li className='ml-10 text-sm uppercase hover:border-b'>Details</li>
            </a>

            <a href='#resources'>
              <li className='ml-10 text-sm uppercase hover:border-b'>
                Resources
              </li>
            </a>
          </ul>
          <div onClick={handleNav} className='md:hidden' size={25}>
            <AiOutlineMenu />
          </div>
        </div>
      </div>

      <div
        className={
          nav ? 'md-hidden fixed left-0 top-0 w-full h-screen bg-black/70' : ''
        }
      >
        <div
          className={
            nav
              ? 'md-hidden fixed left-0 top-0 w-[75%] sm:w-[60%] md:w-[45%] h-screen bg-[#ecf0f3] p-10 ease-in duration-500'
              : 'fixed left-[-100%] top-0 p-10 ease-in duration-500'
          }
        >
          <div>
            <div className='flex w-full items-center justify-between'>
              <a href="https://sivioinstitute.org/" target='_blank' rel='noreferrer'>
                  <img src={logo} width='125' height='50' alt='footerImage' />
              </a>
              <div
                onClick={handleNav}
                className='rounded-full shadow-lg shadow-gray-400 p-3 cursor-pointer'
              >
                <AiOutlineClose />
              </div>
            </div>
            <hr/>
            
            <div className='py-4 flex flex-col'>
              <ul className='uppercase'>
                <a href='#candidates'>
                  <li className='py-4 text-sm'>Presidential Candidates</li>
                </a>

                <a href='#summary'>
                  <li className='py-4 text-sm'>Summary of Activities</li>
                </a>

                <a href='#details'>
                  <li className='py-4 text-sm'>Details</li>
                </a>

                <a href='#resources'>
                  <li className='py-4 text-sm'>Resources</li>
                </a>

              </ul>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Header