
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from '../hooks/useCountdown';

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>ZIM 2023 ELECTIONS HAVE STARTED!!!</span>
      <p>Go to your respective polling stations and cast your vote </p>
    </div>
  );
};


const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <>    <div className="show-counter text-center justify-center">
      <a
        href="https://sivioinstitute.org"
        target="_blank"
        rel="noopener noreferrer"
        className="countdown-link"
      >
        <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
      </a>

      <br/>
      <span className='text-4xl font-extrabold pt-10 ml-5'>To 2023 Elections</span>
    </div>
    

    </>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
      
    );
  }
};

export default CountdownTimer;
