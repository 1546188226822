// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: #7fbc41;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.5rem;
  line-height: 1rem;
  padding: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,oBAAoB;EACpB,4BAA4B;EAC5B,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".expired-notice {\n  text-align: center;\n  padding: 2rem;\n  border: 1px solid #ebebeb;\n  border-radius: 0.25rem;\n  margin: 0.5rem;\n}\n\n.expired-notice > span {\n  font-size: 2.5rem;\n  font-weight: bold;\n  color: #7fbc41;\n}\n\n.expired-notice > p {\n  font-size: 1.5rem;\n}\n\n.show-counter {\n  padding: 0.5rem;\n}\n\n.show-counter .countdown-link {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  font-weight: 700;\n  font-size: 1.25rem;\n  line-height: 1.75rem;\n  padding: 0.5rem;\n  text-decoration: none;\n  color: #000;\n}\n\n.show-counter .countdown {\n  line-height: 1.25rem;\n  padding: 0 0.75rem 0 0.75rem;\n  display: flex;\n  flex-direction: column;\n}\n\n.show-counter .countdown.danger {\n  color: #ff0000;\n}\n\n.show-counter .countdown > p {\n  margin: 0;\n}\n\n.show-counter .countdown > span {\n  text-transform: uppercase;\n  font-size: 0.5rem;\n  line-height: 1rem;\n  padding: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
