import React from 'react'
import { logo } from '../assets'

function Footer() {
    const date = new Date()
  return (
    <div>
        <footer className="bg-gray-900 rounded-lg shadow dark:bg-gray-900 m-4">
    <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
            <a href="https://sivioinstitute.org/" target='_blank' rel='noreferrer' className="flex items-center mb-4 sm:mb-0">
                <img src={logo} width={250} height={300} alt="SIVIO logo" />
            </a>
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0">
                <li>
                    <a href="#candidates" className="mr-4 hover:underline md:mr-6 ">Presidential Candidates</a>
                </li>
                <li>
                    <a href="#summary" className="mr-4 hover:underline md:mr-6">Summary of activities</a>
                </li>
                <li>
                    <a href="#details" className="mr-4 hover:underline md:mr-6 ">Details</a>
                </li>
                <li>
                    <a href="#resources" className="hover:underline">Resources</a>
                </li>
            </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-white sm:text-center">© {date.getFullYear()} <a href="https://sivioinstitute.org/" className="hover:underline">SIVIO Institute</a>. All Rights Reserved.</span>
    </div>
</footer>
    </div>
  )
}

export default Footer