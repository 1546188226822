import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import "@splidejs/splide/dist/css/splide.min.css";

function Media(props) {
  let allData = [];
  allData = props?.resourcesData;
    
  return (
    <div className="flex flex-col">
    <Splide options={{
    type: 'loop',
    rewind:true, 
    autoplay: false,
    perMove: 1,
    perPage: 4, 
    gap: '2rem',
    arrows: true,
    pagination: false,
    autoScroll: {
        pauseOnHover: true,
        pauseOnFocus: false,
        speed: 2
    },
 }}
extensions={{ AutoScroll }}> 
{
  allData.map(reso=>(reso?.category==="Media"?(
    <SplideSlide key={reso?.id}  className={`flex flex-col items-center md:flex-cols-4 bg-[#FEEFE9] max-w-6xl min-w-min max-h-fit shadow-2xl shadow-[#7fbc41] border-solid border-4 border-[#f15b26] rounded-2xl`}>
      <a href={reso?.URLlink} target="_blank" rel="noreferrer">
    <img className='cursor-pointer p-100' src={reso?.PictureURL} alt='resourceImage'/>
    <h1 className='text-center md:font-semibold'>{reso?.Title}</h1>
    </a>
</SplideSlide>):""
  ))
}
</Splide>
       
    </div>
  )
}

export default Media