import { useEffect, useState } from "react";
import moment from "moment";
import "./App.css";
import CountdownTimer from "./components/CountdownTimer";
import axios from "axios";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Resources from "./components/resources/Resources";
import ReactGA from 'react-ga';

export default function App() {
  
  const TRACKING_ID ="G-GJW3LXN97P";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [candidate, setCandidate] = useState([])
  const [summary, setSummary] = useState([])
  var currentTime = new Date();
  var start = currentTime.getTime();
  //var start = moment().startOf('day');
  var end = moment("2023-08-23 08:00:00", "YYYY-MM-DD HH:mm:ss");

  //Difference in number of days
  var diff = moment.duration(end.diff(start)).asDays();

  const THREE_DAYS_IN_MS = diff * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  function getSummaries(){
    axios.get('https://raw.githubusercontent.com/SIVIO-Institute/ElectionsWatchZim/main/summary.json').then(resp=>{
      setSummary(resp?.data?.summaryData)
    }).catch(err=>{
      console.log(err)
    })
  }

  function getCandidates(){
    axios.get('https://raw.githubusercontent.com/SIVIO-Institute/ElectionsWatchZim/main/candidates.json').then(resp=>{
    setCandidate(resp?.data?.candidates)
    }).catch(err=>{
      console.log(err)
    })
  }

  
  useEffect(()=>{
    getSummaries()
    getCandidates()
  //  getResources()
  },[])

  return (
    <div>
      <Header/>
      <div className="w-full text-center border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <h5 className="pt-36 text-3xl font-bold text-gray-900 dark:text-white">
          WELCOME TO ELECTIONS WATCH ZIM
        </h5>
      </div>

      <CountdownTimer targetDate={dateTimeAfterThreeDays} />

      <section id="candidates" className="grid grid-cols-1 items-center pb-10 gap-2 md:grid-cols-4 p-10">
        {
          candidate?.map(cand=>(
            cand?.status!=="Barred"?(
            <div className="card1" key={cand?.id}>
         
          <a href={cand?.URL} target="_blank" rel="noreferrer" className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
    <img className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg" src={cand?.pictureURL} alt="candidate"/>
    <div className="flex flex-col justify-between p-4 leading-normal">
        <h5 className="mb-2 text-sm font-bold tracking-tight text-gray-900 dark:text-white">{cand?.name}</h5>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{cand?.partyName}</p>
    </div>
</a>
        </div>
):""))
        }

      </section>


      <section id="summary">
        <h1 className="text-3xl text-center font-bold p-10 underline">Summary of campaign activities</h1>
    
<p className="text-center p-5">Below is a table that summarises the actions of the political parties on the campaign trail. To explore the details, scroll down to the Power BI visual.
</p>
<hr className="border border-[#7fbc41]"/>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <div className="flex items-center justify-between pb-4 bg-white dark:bg-gray-900"></div>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 group relative">
              <tr>
                <th scope="col" className="px-6 py-3">
                  CANDIDATE
                </th>
                <th scope="col" className="px-6 py-3">
                  RALLIES HELD
                </th>
                <th scope="col" className="px-6 py-3">
                  DENIED RALLIES
                </th>
                <th scope="col" className="px-6 py-3">
                  POLITICAL ARRESTS
                </th>
                <th scope="col" className="px-6 py-3">
                  VIOLENCE
                </th>
                <th scope="col" className="px-6 py-3">
                  SOCIAL MEDIA PRESENCE
                </th>
                <th scope="col" className="px-6 py-3">
                  MEDIA BASED DEBATES
                </th>
                <th scope="col" className="px-6 py-3">
                  PROMISES MADE
                </th>
                <th scope="col" className="px-6 py-3">
                  SMART PROMISES
                </th>
                
                
              </tr>
              <span class="absolute top-5 scale-0 rounded z-[30] bg-gray-800 p-2 text-white text-xs group-hover:scale-100">✨ To view details, scroll down </span>
            </thead>
            <tbody>
              {summary?.map((summ) => (
                summ?.Status!=="Barred"?(
                <tr
                  key={summ?.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-[#7fbc41] dark:hover:bg-gray-600"
                >
                  <th
                    scope="row"
                    className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <img
                      className="w-10 h-10 rounded-full"
                      src={summ?.pictureURL}
                      alt="candidate pic"
                    />
                    <div className="pl-3">
                      <div className="text-base font-semibold">
                        {summ?.Candidates}
                      </div>
                      <div className="flex items-center">
                        <div className={`h-2.5 w-2.5 rounded-full ${summ?.Status==="Running"?`bg-green-500`:summ?.Status==="Pending"?`bg-orange-500`:`bg-black`} mr-2`}></div>{" "}
                        {summ?.Status}{" "}
                      </div>
                    </div>
                  </th>
                  <td className="px-6 py-4 text-gray-900 font-semibold">{summ?.numRallies}</td>
                  <td className="px-6 py-4 text-gray-900 font-semibold">{summ?.denied}</td>
                  <td className="px-6 py-4 text-gray-900 font-semibold">{summ?.arrests}</td>
                  <td className="px-6 py-4 text-gray-900 font-semibold">{summ?.violence}</td>
                  <td className="px-6 py-4 text-gray-900 hover:text-blue-700 hover:underline font-semibold"><a href={summ?.socialURL} target="_blank" rel="noreferrer">{summ?.socialMedia}</a></td>
                  <td className="px-6 py-4 text-gray-900 font-semibold">{summ?.mediaBased}</td>
                  <td className="px-6 py-4 text-gray-900 font-semibold">{summ?.promises}</td>
                  <td className="px-6 py-4 text-gray-900 font-semibold">{summ?.smart}</td>
                </tr>):(
                  ""
                )

                ))}
                
            </tbody>
          </table>
        </div>

       
      </section>

<section id="details">
<h1 className="text-3xl text-center font-bold pt-10 underline">Details on campaign activities</h1>

<div className="p-30 ml-20 mr-20 mt-5">

The 11 presidential candidates are currently on the campaign trail. Below is the interactive platform for you to explore the details captured in the table above.
<h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">The visual has 5 pages for one to navigate:</h2>
<ul class="max-w-max space-y-1 text-gray-900 list-inside dark:text-gray-400">
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Summary: This breakdowns the actions tracked by the Election Watch team
    </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         2023 Activity: The map shows the constituencies with activity. One can drill down to explore more
    </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         Promise Tracker: SMART analysis of the promises made on the campaign trail
    </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         2018 results: Summary of Presidential and Parliamentary results as reported by ZEC
    </li>
    <li class="flex items-center">
        <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
         </svg>
         2023 results: Once elections are under way, this sheet will be updated with reported voter counts
    </li>
</ul>


<strong className="text-red-600">NB: To view the visual in full screen, use the expand button at the bottom right of the frame. Otherwise, go ahead and click/hover to begin to explore the data captured on the Elections Watch tracker.
</strong>

</div>

<div className="flex justify-center">
<iframe
  title="Election Watch Zim tracker V1"
  width="800"
  height="500"
  src="https://app.powerbi.com/view?r=eyJrIjoiYzAxZWE4OWYtZmM0ZC00Y2ZiLThjNDktYTY5YTE3ZmExYWQzIiwidCI6IjQxN2I5MjBkLTc4N2ItNDlhMi1iYTEzLTdkMDlmOGJiNTJkZiJ9"
  frameBorder="0"
  allowFullScreen="true"
></iframe>

</div>
</section>

      
      <section id="resources">
      <h1 className='text-3xl text-center font-bold p-10 underline'>Resources </h1>

<Resources/>
      </section>

<Footer/>

    </div>
  );

  
}
