import React, { useState, useEffect } from "react";
import "../../css/Minerals.css";
import AllResources from "./AllResources";
import Manifestos from "./Manifestos";
import ElectionNotices from "./ElectionNotices";
import Media from "./Media";
import axios from "axios";

function Resources() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);

    if (index === 1) {
      getAllResources();
    } else if (index === 2) {
      getAllResources();
    }else if(index === 3){
      getAllResources()
    }
     else {
      getAllResources();
    }
  };

  useEffect(() => {
    getAllResources();
  }, []);

  const [resourcesData, setResourcesData] = useState([]);
  
    function getAllResources(){
      axios.get('https://raw.githubusercontent.com/SIVIO-Institute/ElectionsWatchZim/main/resources.json').then(resp=>{
      setResourcesData(resp?.data?.resources)
      }).catch(err=>{
        console.log(err)
      })
    }
  
  return (
    <div>
      <div className="w-full bg-white pt-10 pb-10">
       
        <div className="bloc-tabs">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(1)}
          >
            All
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(2)}
          >
            Media/News
          </button>
          <button
            className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(3)}
          >
            Party Manifestos
          </button>
          <button
            className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(4)}
          >
            Election Notices
          </button>
        </div>

        <div className="content-tabs">
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <AllResources resourcesData={resourcesData} />
          </div>

          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          >
            <Media resourcesData={resourcesData} />
          </div>

          <div
            className={
              toggleState === 3 ? "content  active-content" : "content"
            }
          >
            <Manifestos resourcesData={resourcesData} />
          </div>

          <div
            className={
              toggleState === 4 ? "content  active-content" : "content"
            }
          >
          
            <ElectionNotices resourcesData={resourcesData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resources;
