


const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <p className='text-2xl text-[#7FBC41]'>{value}</p>
      <span className=''>{type}</span>
      
    </div>
  );
};

export default DateTimeDisplay;
